
import { Component, Prop, Vue } from 'vue-property-decorator';

import { vxm } from '@/store';
import { pdf } from '@/wapi/pdf-api';

import InvoicePdfCard from './invoice-pdf-card.vue';
import InvoicePdfPage1 from './invoice-pdf-page1.vue';
import InvoicePdfPage2 from './invoice-pdf-page2.vue';

import { IInvoice } from '@/entity/invoice/invoice';
import { IPdfGenerationItem, IPdfGlobalParams } from '@/entity/pdf/pdf-generation-item';
import { IModelInvoice } from '@/entity/pdf/pdf-model-invoice';
import { ISignature } from '@/entity/pdf/pdf-signature';
import { invoiceApi } from '@/wapi/invoice-api';
import { deadlines, IContract } from '@/entity/contract/contract';
import { NumberToWord } from '@t/number-word';
import { IBank } from '@/entity/shared/referential';

@Component({
    components: {
        InvoicePdfCard,
        InvoicePdfPage1,
        InvoicePdfPage2
    }
})
export default class InvoicePdf extends Vue {
    @Prop({ required: true }) id!: string;
    @Prop({ required: true }) invoiceId!: string;
  
    private isVisible: boolean = false;
    private generatingPdf: boolean = false;
    private isComponentMounted: boolean = false; 

    private optionInput: string = '';
    private setOptionInput(option: string) {
        this.optionInput = option;
    }

    private senderEmail: string = 'mail@richezassocies.com';
    private setSenderEmail(value: string) {
        this.senderEmail = value;
    }

    private selectedModel: IModelInvoice | null = null;
    private setSelectedModel(model: IModelInvoice | null) {
        this.selectedModel = model;
        if (!this.selectedModel?.hasOption) {
            this.optionInput = '';
        }
    }

    private clientContact: string = '';
    private setClientContact(value: string) {
        this.clientContact = value;
    }

 
    private handleGenerateWord() {
        if (this.isComponentMounted) {
            this.generateWord(); 
        }
    }

    get currentInvoiceId(): number {
        return ~this.invoiceId;
    }

    private iban: string = '';
    private setIban(value: string) {
        this.iban = value;
    }

    private selectedSignature: ISignature | null = null;
    private setSelectedSignature(signature: ISignature | null) {
        this.selectedSignature = signature;
    }

    get invoice(): IInvoice | undefined {
        const invoice = vxm.project.invoicesList?.find((i) => i.id === +this.invoiceId);
        return invoice;
    }

    get amountTotal(): number {
        return this.invoice?.total ?? 0;
    }

    get amountText(): string {
        return NumberToWord(this.amountTotal);
    }

    get bank(): IBank | undefined {
        const banks = vxm.referential.banks;
        if (this.invoice && this.contract) {
            return banks?.find((b) => b.id === this.contract?.bankId);
        }
    }

    get bankAccount(): string {
        if (this.bank) {
            return `${this.bank.label} ${this.bank.iban.replaceAll(' ', '')} (${this.bank.bic})`;
        }
        return '';
    }

    get dayInvoicing(): string {
        const val = `${this.contract?.terme}${this.contract?.termeEndOfMonth === true ? '+' : '='}`;
        return deadlines.find(x => x.code === val)?.label ?? '';
    }

    private async generateWord() {
        this.isVisible = true; 
        this.generatingPdf = true;
         await this.$nextTick();
        const page1 = this.$refs['pdf-page-1'];
        if (!page1) {
            console.error('page1 est indéfini');
            this.generatingPdf = false; 
            return;
        }

        let styles = '<style type="text/css">';
        // We add styles from page stylesheet
        for (let i = 0; i < document.styleSheets.length; ++i) {
            const styleSheet = document.styleSheets.item(i);
            if (styleSheet && styleSheet.rules) {
                for (let j = 0; j < styleSheet.rules.length; ++j) {
                    styles += styleSheet.rules[j].cssText;
                }
            }
        }
        styles += '</style>';

        const params: IPdfGenerationItem[] = [
            {
                html: `<!DOCTYPE html><html><head><meta charset="UTF-8">${styles}</head><body style="text-rendering: geometricPrecision; margin-left: 25px;">${
                    (page1 as Vue).$el.innerHTML
                }</body>`,
                orientation: 'Portrait'
            }
        ];
        const globalParams: IPdfGlobalParams = {
            iPdfGenerationItem: params, 
            clientId: this.invoice?.clientId,
            invoiceId: this.invoiceId,
            projectId: +this.id,
            amountText: this.amountText,
            clientContact: this.clientContact,
            avancement: this.optionInput,
            iban: this.bankAccount,
            signature: this.selectedSignature,
            selectedModel: this.selectedModel != null ? this.selectedModel : null,
            dayInvoicing: this.dayInvoicing,
        };
        if (globalParams.signature) {
            globalParams.signature.img = globalParams.signature.img.replace('data:image/png;base64,', '');
        }
        const response = await pdf.downloadPdf(globalParams);
        if (response && response.datas) {
            const blob = new Blob([response.datas], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
            vxm.project.fetchInvoices(this.id).then(() => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
               
                link.href = url;
                link.download = (this.invoice?.reference ?? 'sample') + '.docx';
                link.click();
            });
            const body = {
                toAttentionOf: this.clientContact,
                // signingId: this.selectedSignature!.id,
                modelInvoiceId: this.selectedModel!.id,
                progressComment: this.optionInput,
            };
            const result = await invoiceApi.setIsGeneratedToTrue(this.invoiceId, body);
            if (result.cancelled === false && !result.error && result.datas) {
                vxm.project.fetchInvoices(this.id);
            }
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.generatingPdf = false;
        }, 1000);
    }

    private async generatePdf() {
        this.generatingPdf = true;
        const page2 = this.$refs['pdf-page-2'];

        let styles = '<style type="text/css">';
        // We add styles from page stylesheet
        for (let i = 0; i < document.styleSheets.length; ++i) {
            const styleSheet = document.styleSheets.item(i);
            if (styleSheet && styleSheet.rules) {
                for (let j = 0; j < styleSheet.rules.length; ++j) {
                    styles += styleSheet.rules[j].cssText;
                }
            }
        }
        styles += '</style>';

        const params: IPdfGenerationItem[] = [
            {
                html: `<!DOCTYPE html><html><head><meta charset="UTF-8">${styles}</head><body>${(page2 as Vue).$el.innerHTML
                }</body>`,
                orientation: 'Landscape'
            }
        ];
        const response = await pdf.downloadNdfPdf(params);

        if (response && response.datas) {
            const blob = new Blob([response.datas], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = (this.invoice?.reference ?? 'sample') + '.pdf';
            link.click();
            const body = {
                toAttentionOf: this.clientContact,
                // signingId: this.selectedSignature!.id,
                modelInvoiceId: this.selectedModel!.id,
                progressComment: this.optionInput,
            };
            const result = await invoiceApi.setIsGeneratedToTrue(this.invoiceId, body);
            if (result.cancelled === false && !result.error && result.datas) {
            }
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.generatingPdf = false;
        }, 1000);
    }

    private goBack() {
        this.$router.push({ name: 'project-information', params: { id: this.id } });
    }

    async mounted(): Promise<void> {
        vxm.referential.fetchBanks();
        vxm.referential.fetchThirdPartyAddressType();
        vxm.project.fetchInvoices(this.id);
        vxm.project.fetchContracts(this.id);

        this.isComponentMounted = true; 
    }

    get contract(): IContract | undefined {
        return vxm.project.contractsList?.find((c) => c.id === this.invoice?.contractId);
    }
}
